.news-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 445px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    max-height: 385px;
  
    .card-action-area {
      display: flex;
      flex-direction: column;
    }
  
    .card-media {
      width: 100%;
      height: auto;
      max-height: 200px;
      object-fit: cover;
    }
  
    .card-content {
      flex: 1;
      padding: 16px;
    }
  
    .card-description {
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .card-actions {
      display: flex;
      justify-content: center;
      padding: 8px 16px;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .news-card {
      max-width: 100%;
    }
  
    .card-media {
      max-height: 150px;
    }
  }
  