@import '../theme/colors.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: $headerBackgroundColor;
  color: $headerFontColor;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-left {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.header-logo {
  height: 60px;
  margin-right: 10px;
}

.header-title {
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right .header-button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: $signInButtonColor;
  color: $whiteColor;
  border: none;
  cursor: pointer;
  border-radius: 16px;
}

.header-right .header-button:hover {
  background-color: $buttonHoverColor;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .header-left {
    margin-bottom: 10px;
  }

  .header-right {
    margin-top: 10px;
  }
}
