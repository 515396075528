
  $primaryColor: #1976D2;
  $secondaryColor: #FF4081;
  $backgroundColor: #FFFFFF;
  $textColor: #333333;
  $errorColor: #FF5722;
  $footerBackgroundColor: #FAF9F6;
  $footerFontColor: #302929;
  $socialIconFbColor: rgb(1, 107, 143);
  $socialIconInstaColor: rgb(228, 21, 107);
  $headerBackgroundColor: #FAF9F6;
  $headerFontColor: #302929;
  $signInButtonColor: #469bdb;
  $whiteColor: #ffffff;
  $buttonHoverColor: #777;
  $LoginFontColor: #469bdb;


  