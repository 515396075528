@import '../theme/colors.scss';

.besnu {
  color: $buttonHoverColor !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
  text-align: start;
  // margin-left: 8% !important;
}

.welcome-pages {
  text-align: center;
  padding: 20px;
}

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 100px;
}

.columns {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.deaths-containers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.no-data-icon {
  font-size: 80px;
  color: gray;
}

.no-data-text {
  font-size: 1.5rem;
  color: gray;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .contents {
    flex-direction: column;
    align-items: center;
  }

  .columns {
    width: 90%; /* Make columns narrower on medium screens */
  }

  .deaths-containers {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .columns {
    width: 100%;
    padding: 10px;
  }

  .deaths-containers {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }
}
