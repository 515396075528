@import '../theme/colors.scss';

.footer {
  text-align: center;
  padding: 10px;
  background-color: $footerBackgroundColor;
  color: $footerFontColor;
  font-weight: 600;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
}

// .social-icons {
//   display: flex;
//   justify-content: center;
//   margin-bottom: 10px;
// }

.links {
  // display: flex;
  // justify-content: center;
  // margin-bottom: 10px;
  margin-top: 5px;

  .footer-link {
    text-decoration: none;
    color: #007BFF; /* Link color */
    font-weight: bold;
    margin: 10 10px;

    &:hover {
      text-decoration: underline;
      color: #0056b3; /* Darker color on hover */
    }
  }
}

.footer-text {
  margin-top: 6px;
}

.social-icon-fb, .social-icon-insta {
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
  color: inherit; /* Inherit color from parent element */
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
    font-size: 14px;
  }

  .social-icons {
    margin-bottom: 15px;
  }

  .social-icon-fb, .social-icon-insta {
    font-size: 20px;
    margin: 0 3px;
  }

  .links .footer-link {
    margin: 0 5px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 20px 5px;
    font-size: 12px;
  }

  .social-icons {
    margin-bottom: 15px;
  }

  .social-icon-fb, .social-icon-insta {
    font-size: 18px;
    margin: 0 2px;
  }

  .links .footer-link {
    margin: 0 3px;
  }
}
