.welcome-page {
  text-align: center;
  padding: 20px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 100px;

  .column {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 15px;
    }

    .events-container,
    .deaths-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* 2 columns for both events and deaths */
      gap: 20px; /* Gap between grid items */
      justify-items: center;
    }
  }
}

.datacontent {
  padding: 0px 40px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .content {
    flex-direction: column !important;
    align-items: center  !important;
  }

  .column {
    width: 100% !important; /* Make columns full width on tablets */
  }

  // .events-container,
  // .deaths-container {
  //   grid-template-columns: 1fr !important; /* 1 column on tablets */
  // }
}

@media (max-width: 1024px) {
  .column {
    .events-container,
    .deaths-container {
      grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    }
  }
}

@media (max-width: 768px) {
  .column {
    width: 100% !important;
    padding: 10px !important;
  }

  .events-container,
  .deaths-container {
    grid-template-columns: 1fr !important; /* 1 column on small screens */
  }
}

/* Additional styling for very small devices */
@media (max-width: 480px) {
  .column {
    padding: 5px !important;
  }

  .events-container,
  .deaths-container {
    gap: 10px !important; /* Reduce gap between grid items */
  }
}