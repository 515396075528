@import '../theme/colors.scss';

.mainscreen {
    background-color: $headerBackgroundColor !important;
    color: $headerFontColor !important;
}

.sidebarFont {
    font-size: 0.5rem !important;
    font-family: 'monospace' !important;
}

.hide-scrollbar {
    overflow: hidden !important;
    scrollbar-width: none !important; /* Firefox */
  }

  .icon-button-custom {
    padding-left: 45px !important;
    transition: background-color 0.3s !important; /* Optional for smooth transition */
  }
  
  .icon-button-custom:hover {
    background-color: transparent !important;
  }
  
//   .hide-scrollbar::-webkit-scrollbar {
//     width: 0px !important; /* Chrome, Safari */
//   }
  
//   .hide-scrollbar::-webkit-scrollbar-thumb {
//     background: transparent !important;
//   }