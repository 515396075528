.make-donation-container {
    .make-donation-card {
      width: 100%;
    }
  
    .input-label {
      font-size: small;
      font-weight: bold;
    }
  
    @media (max-width: 768px) {
      .input-label {
        font-size: smaller;
      }
    }
  }
  