.news-page-bd {
    text-align: center;
    padding: 20px;
  }
  
  .news-content-bd {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .news-scroll-container-bd {
    overflow-y: auto;
    max-height: calc(100vh - 200px); /* Adjust according to your layout */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    padding-bottom: 20px; /* Ensure padding at the bottom for better UX */
  }
  
  .news-scroll-container-bd::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
  }
  
  .news-desc-bd {
    overflow: hidden; /* Ensure the content is hidden if it overflows */
    margin-bottom: 10px; /* Add margin for spacing between paragraphs */
  }
  
  .news-desc-bd-1 {
    overflow: hidden; /* Ensure the content is hidden if it overflows */
    // margin-bottom: 10px; /* Add margin for spacing between paragraphs */
  }

  .news-desc-bd-2 {
    overflow: hidden; /* Ensure the content is hidden if it overflows */
    margin-bottom: 20px; /* Add margin for spacing between paragraphs */
  }

  .news-image-bd {
    width: 100%;
    height: auto;
    max-height: 800px; /* Adjust as necessary */
    object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
    margin-bottom: 10px;
  }
  
  .header-container-bd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: relative;
  }
  
  .header-container-bd .title-bd {
    // position: absolute;
    left: 50%;
    // transform: translateX(-50%);
    margin: 0;
    text-align: center;
    flex-grow: 1;
  }
  
  .header-container-bd .MuiIconButton-root {
    position: absolute;
    left: 0;
  }
  