  .content-policy {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    // margin-top: 20px;
    // margin-bottom: 100px;
    padding: 20px;
    max-height: calc(100vh - 200px); /* Adjust according to header and footer height */
    overflow-y: auto;
    // position: relative;
  }
  
  .datacontent-policy {
    padding: 20px 60px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .content-policy {
    //   flex-direction: column;
    //   align-items: center;
      padding: 20px;
      max-height: calc(100vh - 400px); /* Adjust according to header and footer height */
      overflow-y: auto;
    //   position: relative;
    }
  }
  
  @media (max-width: 768px) {
    .column-policy {
    //   width: 100%;
    //   padding: 10px;
      padding: 20px;
    //   position: relative;
      max-height: calc(100vh - 400px); /* Adjust according to header and footer height */
      overflow-y: auto;
    }
  }

  .header-container-policy {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px; 
  }
  
  .back-arrow-policy {
    position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 30px;
  }
  
  .title-policy {
    text-align: center;
  flex-grow: 1;
  margin: 0;
  }
  