@import '../theme/colors.scss';

.login {
    margin-bottom: 7px !important;
    font-size: 30px !important;
    font-weight: 700;
    color: $LoginFontColor;
}

.login-main {
    margin-top: 92px !important;
}

.login-page {
    background-image: url('../assets/background/background.png'); /* Replace '../path/to/your/image.jpg' with the path to your image file */
    background-size: cover;
    background-position: center;
    height: 80vh;
  }

  @media (max-width: 768px) { 
    .login-page {
        // background-image: url('../assets/background/background.png'); /* Replace '../path/to/your/image.jpg' with the path to your image file */
        // background-size: cover;
        // background-position: center;
        height: 90vh;
      }

      .login-main {
        margin-top: 2px !important;
    }
  }

  @media (max-width: 670px) { 
    .login-page {
        // background-image: url('../assets/background/background.png'); /* Replace '../path/to/your/image.jpg' with the path to your image file */
        // background-size: cover;
        // background-position: center;
        height: 90vh;
        padding: 0px 14px !important;
      }
  }

  @media (max-width: 350px) { 
    .login-page {
        // background-image: url('../assets/background/background.png'); /* Replace '../path/to/your/image.jpg' with the path to your image file */
        // background-size: cover;
        // background-position: center;
        height: 95vh;
        padding: 0px 12px !important;
      }

      .login-main {
        margin-top: 4px !important;
    }

    .login {
        font-size: 20px !important;
    }
  }