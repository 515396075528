@import '../theme/colors.scss';

/* About.css */

.about-container {
    // margin-top: 1rem;
    margin-bottom: 3rem;
    padding: 2rem;
  }
  
  @media (min-width: 600px) {
    .about-container {
      padding: 3rem;
    }
  }
  
  @media (min-width: 960px) {
    .about-container {
      padding: 5rem;
    }
  }
  
  .about-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .about-card {
    padding: 2rem;
  }
  
  @media (min-width: 600px) {
    .about-card {
      padding: 3rem;
    }
  }
  
  @media (min-width: 960px) {
    .about-card {
      padding: 4rem;
    }
  }
  