.news-page {
  text-align: center;
  padding: 0px 20px;

  .news-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px 0;

    // .MuiIconButton-root {
    //   position: absolute;
    //   left: 0;
    // }

    .news-title {
      margin: 0 auto;
    }
  }

  .news-content {
    max-width: 800px;
    margin: 0 auto;

    .news-scroll-container {
      overflow-y: auto;
      margin-bottom: 10px;
      // max-height: calc(100vh - 200px); /* Adjust according to your layout */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
    }

    .news-scroll-container::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .news-desc {
      overflow: hidden; /* Ensure the content is hidden if it overflows */
      height: 100vh !important;
    }

    .news-image {
      width: 70%;
      height: auto;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .news-page {
    padding: 0 10px;

    .news-content {
      .news-image {
        width: 100%;
      }
    }
  }
}
