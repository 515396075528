.mainSignUp {
    overflow-y: auto;
    max-height: calc(100vh - 184px); /* Adjust the value as needed */
  }
  
  @media (max-width: 830px) {
    .mainSignUp {
        overflow-y: auto;
      max-height: calc(100vh - 260px); /* Adjust the value as needed */
    }
  }

  @media (max-width: 780px) {
    .mainSignUp {
        overflow-y: auto;
      max-height: calc(100vh - 280px); /* Adjust the value as needed */
    }
  }

  @media (max-width: 400px) {
    .mainSignUp {
        overflow-y: auto;
      max-height: calc(100vh - 312px); /* Adjust the value as needed */
    }
  }

  @media (max-width: 360px) {
    .mainSignUp {
        overflow-y: auto;
      max-height: calc(100vh - 331px); /* Adjust the value as needed */
    }
  }

  @media (max-width: 331px) {
    .mainSignUp {
        overflow-y: auto;
      max-height: calc(100vh - 430px); /* Adjust the value as needed */
    }
  }


