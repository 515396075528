/* Appdonors.css */

@import '../theme/colors.scss';

.appdonors-container {
    flex-grow: 1;
    padding: 24px;
  }
  
  .appdonors-grid {
    margin-bottom: 24px;
  }
  
  .appdonors-card {
    cursor: pointer;
    min-height: 100%;
    // min-width: 330px;
    background-color: #FAF9F6;
  }
  
  .appdonors-icon {
    font-size: 60px !important;
  }
  
  .appdonors-title {
    font-size: 20px !important;
    margin-left: 8px !important;
  }
  