/* AppDonorsList.css */

.appdonors-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
  }
  
  .appdonors-header {
    margin-top: 2%;
    display: flex;
    align-items: center;
  }
  
  .appdonors-header-title {
    margin-left: 8px;
  }
  
  .donor-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding-top: 16px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 24px;
    border: none;
  }
  
  .modal-close-button {
    margin-left: 90%;
  }
  
  .modal-image {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 600px) {
    .appdonors-container {
      padding: 16px;
    }
  
    .no-data-container {
      padding: 16px;
    }
  
    .modal-content {
      width: 300px;
      padding: 16px;
    }
  
    .modal-close-button {
      margin-left: 85%;
    }
  }
  