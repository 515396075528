.business-details-container {
    padding: 20px;
  
    .back-button {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
  
      svg {
        margin-right: 10px;
      }
    }
  
    .business-details-card {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      @media (min-width: 600px) {
        flex-direction: row;
        align-items: flex-start;
      }
  
      .business-image {
        width: 100%;
        max-width: 300px;
        height: 30%;
        // padding-top: 100%; /* 1:1 aspect ratio */
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 20px;
  
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
     
        .business-info {
            flex: 1;
            margin-top: 20px;
    
            @media (min-width: 600px) {
              margin-top: 0;
              margin-left: 20px; /* Add spacing between image and info on larger screens */
            }
    
            .business-name {
              font-size: 22px;
              font-weight: 500;
              margin-bottom: 10px;
              text-align: center; /* Center align for mobile view */
    
              @media (min-width: 600px) {
                text-align: left; /* Align left for larger screens */
              }
            }
    
            .business-description {
              font-size: 16px;
              margin-bottom: 15px;
              text-align: center; /* Center align for mobile view */
    
              @media (min-width: 600px) {
                text-align: left; /* Align left for larger screens */
              }
            }
    
            .business-details-list {
              list-style-type: none;
              padding: 0;
              margin: 0;
    
              li {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
    
                svg {
                  margin-right: 10px;
                  flex-shrink: 0;
                  width: 24px;
                  height: 24px;
                }
    
                span {
                  font-size: 14px;
                }
              }
            }
    
            .social-icons {
              display: flex;
              justify-content: center;
              margin-top: 20px;
    
              @media (min-width: 600px) {
                justify-content: flex-start; /* Align icons to the left on larger screens */
              }
    
              a {
                margin: 0 10px;
              }
            }
          }
        }
      }
    }
    
  